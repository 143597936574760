.noda-payment-button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 55px;
    width: 100%;
    background: #fff;
    border: 1px solid #2c2e2f;
    border-radius: 4px;
    margin: 12px auto 0 auto;
}