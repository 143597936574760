

























































































































































.fee {
  width: 100%;
  text-align: center;
  margin: 10px auto 0 auto;

  &-amount {
    font-family: "Raleway Medium", serif;
    font-size: 16px;
    margin: 0;

    &__icon {
      cursor: pointer;
    }
  }

  &-checkbox::v-deep {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    font-family: "Raleway Regular", serif;
    font-size: 16px;

    .el-checkbox__label {
      white-space: normal;
      text-align: left;
      margin-right: 5px;
    }
  }

  &-dialog {
    &__text {
      font-family: "Raleway Regular", serif;
      font-size: 16px;
      word-break: break-word;
    }
  }
}
