
























































.currency-input {
  position: relative;
  &-field {
    width: 100%;

    &:focus {
      outline: none;
      border-color: #FF8A00;
    }

    .el-input__inner {
      text-align: center;
      height: 60px;
      background: #F6F7F9;
      border: 1px solid #E3E5E6;
      border-radius: 8px;
      font-family: "Raleway Bold", serif;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: #2D2E2F;
      font-size: 24px;
      font-weight: bold;
    }
  }

  &-clear {
    position: absolute;
    top: 5px;
    right: 5px;
    height: 50px;
    width: 50px;
    background: #FFFFFF;
    border-radius: 50%;

    i {
      position: absolute;
      top: 14px;
      right: 13px;
      cursor: pointer;

      &:before {
        font-size: 24px;
        color: #FF8A00;
        font-weight: bold;
      }
    }
  }
}
