


















































































































































































.button-wrapper {
  margin: 12px auto 0 auto;
}

#payment-request-button::v-deep {
  border: 1px solid #2c2e2f !important;
  border-radius: 4px !important;
  overflow: hidden;

  .__PrivateStripeElement {
    box-shadow: none !important;
  }
}

.youtube-player {
  width: 100%;
}
