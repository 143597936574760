












































































































































.paypal-button::v-deep {
  .zoid-visible {
    z-index: 10 !important;
  }
}

#paypal-button {
  z-index: 1;
  position: relative;
  margin: 12px auto 0 auto;
}
