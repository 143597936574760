












































































































































.radio-button-group {
  height: 45px;
  background: #f6f7f9;
  border-radius: 24px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .checked-btn {
    background-color: var(--active-bg);
    color: #ffffff;
    box-shadow: var(--active-shadow);
    border-radius: 24px;
  }

  &-item {
    width: 100%;
    height: 100%;
    margin: 0;
    appearance: none;
    outline: none;
    cursor: pointer;
    color: #2d2e2f;
    font-size: 14px;
    font-family: "Raleway Bold", serif;
    font-feature-settings: "pnum" on, "lnum" on;

    &.stars:after {
      background-image: url(https://tippiie-images.s3.eu-central-1.amazonaws.com/stars.gif);
      background-size: 100px 100px;
      background-repeat: no-repeat;
      display: block;
      position: absolute;
      width: 100px;
      height: 100px;
      right: 20px;
      bottom: 0px;
      content: "";
    }

    &:before {
      content: attr(label);
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }
}
