












































































.further-payment-methods-drawer {
  .el-drawer__header {
    margin-bottom: 0 !important;
  }
}
