



















































































.credit-card-button::v-deep {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #2c2e2f;
  border-radius: 4px;
  margin: 12px auto 0 auto;

  span {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      margin-right: 8px;
    }

    p {
      margin: 0;
      font-family: "Raleway SemiBold", serif;
      font-size: 24px;
    }
  }
}
